<template>
  <!--<div>
    <h1>Hello World!</h1>
    <a class="btn btn-primary"><i class="fa fa-user"></i> Contactez-nous</a>
  </div>-->
  <body class="main-layout">
   <!-- loader  -->
   <div class="loader_bg" v-show="showLoader">
      <div class="loader"><img src="../assets/images/loading.gif" alt="#" /></div>
   </div> 
   <!-- end loader -->
   <!-- header -->
   <Entete/>
   <!-- end banner -->
   <!-- feature -->
   <div id="about" class="feature">
      <div class="container">
         <div class="row">
            <div class="col-md-12">
               <div class="titlepage">
                  <h2>Concrètement c'est quoi<strong class="pink"> Geco </strong></h2>
                  <span>Si vous êtes un gérant de pharmacie, grossite des boissons et  PPN, détaillants, vente des pièces, etc; ce logiciel est fait pour vous.
                    Il contient plusieurs foctionnalités tel que la gestion des produits, approvisionnement, gestion de stock, gestion des points de ventes,caisse,rapport des ventes et bien d'autres<br>
                     N'attendez plus, faite votre commande pour optimisez votre activité commerciale</span>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-8 offset-md-2 ">
               <div class="feature_box ">
                  <figure><img src="../assets/images/design.png" alt="#" /></figure>
                  <!--<router-link  class="read_more" to="/creation-compte"><i class="fa fa-sign-in"></i> Demo</router-link >-->
                  <a class="read_more" href="https://geco.aymarinfo.com/dashboard"><i class="fa fa-sign-in"></i> Demo</a>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- feature -->
   <!-- amezing -->
   <div id="" class="amezing">
      <div class="container">
         <div class="row">
            <div class="col-md-7">
               <div class="amezing_box">
                  <div class="titlepage">
                     <h2>Pourquoi utiliser notre <strong class="pink"> <br> logiciel GECO </strong></h2>
                  </div>
                  <div class="can">
                     <span>01</span>
                     <h4>Facile à utiliser</h4>
                  </div>
                  <div class="can">
                     <span>02</span>
                     <h4>Prix abordable</h4>
                  </div>
                  <div class="can">
                     <span>03</span>
                     <h4>Assistance client satisfaisante</h4>
                  </div>
   
               </div>
            </div>
            <div class="col-md-5">
               <div class="amezing_box">
                  <figure><img src="../assets/images/logiciel.png"></figure>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- end amezing -->
   <!-- request -->
   <div id="contact" class="request">
      <div class="container">
         <div class="row">
            <div class="col-md-12">
               <div class="titlepage">
                  <h2>Contactez- <strong class="pink"> nous</strong></h2>
                  <span> Vous pouvez nous contactez avec les numéros et email suivant </span>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-3">
               <ul class="social_infomision">
                  <li><a href="#"><img src="../assets/images/call.png" alt="#" />+261 32 61 882 94</a></li>
               </ul>
            </div>
            <div class="col-md-3">
               <ul class="social_infomision">
                  <li><a href="#"><img src="../assets/images/call.png" alt="#" />+261 33 67 530 80</a></li>
               </ul>
            </div>
            <div class="col-md-6">
               <ul class="social_infomision">
                  <li><a href="#"><img src="../assets/images/email.png" alt="#" />contact@aymarinfo.com</a></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
   <!-- end request -->
   <!--  footer -->
   <CreationCompte></CreationCompte>
     <Footer></Footer>
</body>
</template>

<script>
//import vueScrollTo from 'vue-scroll-to';
import Entete from '../components/Entete.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'HomePage',
    components:{
        Entete,Footer
    },
    data(){
        return {
            showLoader: true,
        };
    },
    mounted(){
        setTimeout(()=>{
            this.showLoader = false;
        }, 1000)
    },
 
}
</script>

<style scoped>
    /* Vos styles spécifiques au composant ici */
    body {
    margin-top: -60px !important;
    }
</style>