
const createCompte = () => import('./components/CreationCompte.vue')
const HomePage = () =>  import('./components/HomePage.vue')
const mesRoutes = [
    {
        name:'accueil',
        path: '/',
        component:HomePage
    },
    {
        name:'createCompte',
        path: '/creation-compte',
        component:createCompte
    }
]
export default mesRoutes;