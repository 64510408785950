import {createApp} from 'vue'
import App from './App.vue'
//import vueScrollTo from 'vue-scroll-to';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import 'bootstrap';
import VueRouter  from 'vue-router';
import mesRoutes from './routes';

const  router =  new VueRouter({
    mode: 'history',
    routes: mesRoutes
  });
//createApp.use(vueScrollTo);

/* Vue({
    render: h => h(App),
    router
  }).$mount('#app');*/

createApp(App).use(router).mount('#app')
