<template>
    <header>
      <!-- header inner -->
      <div class="head_top">
         <div class="header">
            <div class="container">
               <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                     <div class="full">
                        <div class="center-desk">
                           <div class="logo">
                              <a href="/"><img width="100px" src="../assets/images/icon.png" alt="#" /></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                     <nav class="navigation navbar navbar-expand-md navbar-dark ">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                           data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false"
                           aria-label="Toggle navigation">
                           <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarsExample04">
                           <ul class="navbar-nav mr-auto">
                              <li class="nav-item">
                                 <a class="nav-link" href="#about">à propos</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" href="#contact">Contacts</a>
                              </li>
                           </ul>
                           <div class="sign_btn"><a href="https://geco.aymarinfo.com/dashboard">Demo</a></div>
                        </div>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
         <!-- end header inner -->
         <!-- end header -->
         <!-- banner -->
         <section class="banner_main">
            <div class="container-fluid">
               <div class="banner_bg">
                  <div class="row d_flex">
                     <div class="col-xl-6 col-lg-6 col-md-12 padding_right1">
                        <div class="text_box_color">
                           <div class="text-bg">
                              <h1>GECO, <br>votre logiciel moderne</h1>
                              <strong>de gestion commerciale multi-point de vente</strong>
                              <span>Pour les grossistes ou détaillants</span>
                              <a href="#about"><i class="fa fa-plus"></i> Voir plus</a>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-12 padding_right1">
                        <div class="text-img">
                           <figure><img src="../assets/images/top_img3.png" alt="#" /></figure>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   </header>
</template>

<script>
 export default {

    name: 'entete-site',
 }

</script>