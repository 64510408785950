<template>
  <footer>
      <div class="footer">
         <div class="container">
            <div class="row">
               <div class="col-md-10 offset-md-1">
                  <div class="cont">
                     <h3>Nous somme aussi présent dans les réseaux sociaux</h3>
                     <span> Pour contacter ou suivre nos activités, rien de plus éfficace que de suivre nos réseaux sociaux.</span>
                     <ul class="social_icon">
                        <li> <a href="https://www.facebook.com/profile.php?id=100086957756396&locale=fr_FR"><i class="fa fa-facebook-f"></i></a></li>
                        <li> <a href="https://aymarinfo"><i class="fa fa-globe"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin-square"></i></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="copyright">
            <div class="container">
               <div class="row">
                  <div class="col-md-12">
                     <p>© 2024 All Rights Reserved</p>
                     <p>Dévéloppé par : <a href="https://aymarinfo.com">aymarinfo</a></p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>
<script>
export default{
    name:'app-footer',
};
</script>
